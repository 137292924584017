import { APIParams } from '@ancon/wildcat-types'

function createUrlParams(params?: APIParams) {
  return (
    params &&
    Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )
}

function getInternalBaseURL(): string | undefined {
  const environmentName = process.env.NEXT_PUBLIC_ENVIRONMENT

  // Running on the server, use the internal media API URL if it's defined
  switch (environmentName) {
    case 'staging1':
      return process.env.INTERNAL_MEDIA_API_STAGING1_URL
    case 'staging2':
      return process.env.INTERNAL_MEDIA_API_STAGING2_URL
    case 'staging3':
      return process.env.INTERNAL_MEDIA_API_STAGING3_URL
    case 'qa1':
      return process.env.INTERNAL_MEDIA_API_QA1_URL
    case 'qa2':
      return process.env.INTERNAL_MEDIA_API_QA2_URL
    case 'qa3':
      return process.env.INTERNAL_MEDIA_API_QA3_URL
    default:
      return process.env.INTERNAL_MEDIA_API_COMMON_URL
  }
}

function getBaseURL(): string {
  if (typeof window === 'undefined') {
    // Running on the server, use the internal media API URL
    return getInternalBaseURL() ?? process.env.NEXT_PUBLIC_MEDIA_API_URL
  }

  // Running on the client, always use the public media API URL
  if (!process.env.NEXT_PUBLIC_MEDIA_API_URL) {
    throw new Error(
      'NEXT_PUBLIC_MEDIA_API_URL is not defined in the client environment',
    )
  }

  return process.env.NEXT_PUBLIC_MEDIA_API_URL
}

export default function getImageUrl(
  imageId: string,
  params?: APIParams,
  version: number = 1,
) {
  const queryParams = createUrlParams(params)

  return `${getBaseURL()}/v${version}/media/${imageId}${queryParams ? `?${queryParams}` : ''}`
}
